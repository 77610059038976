


















import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { CompanyService } from "@/services/Public";
import { MediaModel } from "@/models/common";
import NewMessageDialog from "@/components/dialogs/NewMessageDialog.vue";

@Component({
  name: "MessageDialog",
  components: {
    NewMessageDialog,
  },
})
export default class MessageDialog extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly companyName!: string;

  @Prop({ required: true, default: "" })
  public readonly companyId!: string;

  private recipients: unknown[] = [];

  private subject = "";

  private messageBody = "";

  private sending = false;

  private mediaLoading = false;

  private selectedMedia: MediaModel | null = null;

  private media: MediaModel[] = [];

  @Watch("value")
  async watchValue(value: boolean) {
    if (value) {
      this.recipients.push({
        text: this.companyName,
        value: this.companyId,
      });
    } else {
      this.messageBody = "";
      this.subject = "";
      this.recipients = [];
      this.media = [];
      this.selectedMedia = null;
    }
  }

  async refreshMedia() {
    try {
      this.mediaLoading = true;
      this.media = await CompanyService.getMedia();
    } catch (e) {
      console.log("Media fetch error:", e);
    } finally {
      this.mediaLoading = false;
    }
  }

  async send() {
    try {
      this.sending = true;
      await CompanyService.sendMessage(
        this.companyId,
        this.subject,
        this.messageBody,
        this.selectedMedia?.id
      );
      this.input(false);
    } catch (e) {
      console.error("Message sending error:", e);
    } finally {
      this.sending = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
