

































































































import Vue from "vue";
import Component from "vue-class-component";
import ApplyCardWrapper from "@/views/shared/ApplyCardWrapper.vue";
import { Emit, Prop } from "vue-property-decorator";
import { CompanyPageModel } from "@/models/Page";
import BaseLink from "@/components/common/BaseLink.vue";
import { getMapLink } from "@/utils/generateMapLink";
import CompanyMenu from "@/views/CompanyPage/CompanyMenu.vue";
import ECompanyPageMenuAction from "@/models/enums/ECompanyPageMenuAction";
import { MenuItemModel } from "@/models/common";

@Component({
  name: "CompanyApplyCard",
  components: {
    CompanyMenu,
    BaseLink,
    ApplyCardWrapper,
  },
})
export default class extends Vue {
  @Prop({ required: true, type: Object, default: () => ({}) })
  public readonly data!: CompanyPageModel;

  @Prop({ required: true, default: false })
  public readonly showActions!: boolean;

  get isFavorite() {
    return this.data?.isFavorite;
  }

  get isHide() {
    return this.data?.isHide;
  }

  get avatar(): string {
    return this.data?.company?.avatar?.fileUrl;
  }

  get name(): string {
    return this.data?.company?.name;
  }

  get website(): string {
    return this.data?.company?.website;
  }

  get address(): string {
    return this.data?.company?.address;
  }

  get phone(): string {
    //return this.data?.company?.phoneNumber;

    const number = this.data?.company?.phoneNumber;
    return number ? number : "";
  }

  get phoneNumber(): string {
    return `tel:${this.phone.replace(/\D/g, "")}`;
  }

  get disabledReport(): boolean {
    return this.data?.isBlockedByThisUser;
  }

  mapLink(path: string): string {
    return getMapLink(path);
  }

  @Emit()
  message() {}

  @Emit()
  menuItemSelected(value: MenuItemModel) {
    switch (value.action) {
      case ECompanyPageMenuAction.Favorite:
        return { ...value, isChecked: !this.isFavorite };

      case ECompanyPageMenuAction.Hide:
        return { ...value, isChecked: !this.isHide };
      default:
        break;
    }
    return value;
  }
}
