














































































import Vue from "vue";
import Component from "vue-class-component";
import ChipsList from "@/components/lists/ChipsList/index.vue";
import OpportunitiesList from "@/components/lists/OpportunitiesList/index.vue";
import MediaSectionList from "@/components/lists/MediaSectionList/index.vue";
import { MediaModel, SocialLinkModel } from "@/models/common";
import CompanyApplyCard from "@/views/CompanyPage/CompanyApplyCard.vue";
import TextViewer from "@/components/common/TextViewer.vue";
import MessageDialog from "./dialogs/MessageDialog.vue";
import { IUpdateHeaderFooterData } from "@/store/modules/headerFooterInfo";
import { namespace } from "vuex-class";
import JobOpportunities from "@/views/shared/JobOpportunities.vue";
import { PageService } from "@/services/Public";
import CompanyService from "@/services/Public/CompanyService";
import ECompanyPageMenuAction from "@/models/enums/ECompanyPageMenuAction";
import { CompanyPageModel } from "@/models/Page";
import { ActionTypes } from "@/models/requests/company/ActionsModels";
import { MenuItemModel } from "@/models/common";
import PageContainer from "@/views/shared/PageContainer.vue";
import PageContainerHeader from "@/views/shared/PageContainerHeader.vue";
import LocationMarker from "@/views/shared/LocationMarker.vue";
import SocialLinksList from "@/views/shared/SocialLinksList.vue";
import ReportConfirmationDialog from "@/views/CandidateProfile/dialogs/ReportConfirmationDialog.vue";
import SimilarOpportunities from "@/views/shared/SimilarOpportunities.vue";
import { ECandidatePageMenuAction } from "@/models/enums";
// import AlertNotesDialog from "@/views/CompanyPage/dialogs/AlertNotesDialog.vue";

interface IIconsKeys {
  [key: string]: string;
}

const icons: IIconsKeys = {
  Instagram: "instagram.svg",
  Facebook: "fb.svg",
  Linkedin: "linkedin.svg",
  Twitter: "twitter.svg",
  GitHub: "git.svg",
  "Khan Academy": "kh.svg",
};

const HeaderFooterInfo = namespace("headerFooterInfo");

@Component({
  name: "CompanyPage",
  components: {
    // AlertNotesDialog,
    ReportConfirmationDialog,
    PageContainer,
    JobOpportunities,
    MessageDialog,
    TextViewer,
    CompanyApplyCard,
    MediaSectionList,
    PageContainerHeader,
    LocationMarker,
    SocialLinksList,
    ChipsList,
    OpportunitiesList,
    SimilarOpportunities,
  },
})
export default class extends Vue {
  @HeaderFooterInfo.Action("updateData")
  updateHeaderFooterInfo!: IUpdateHeaderFooterData;

  private companyData: CompanyPageModel | null = null;

  private featuredOpportunitiesToShow = 2;

  private loading = false;

  private showReportModal = false;

  private loadingReport = false;

  private showMessageDialog = false;

  // private showNotesModal = false;

  async beforeMount() {
    await this.getCompanyData();
  }

  get coverImg() {
    return this.companyData?.company?.cover?.originalUrl;
  }

  get avatar(): string | undefined {
    return this.companyData?.company?.avatar?.fileUrl;
  }

  get name(): string | undefined {
    return this.companyData?.company?.name;
  }

  get location(): string | undefined {
    return this.companyData?.company?.location;
  }

  get socialNetworksList() {
    return this.companyData?.company?.socialLinks.map(
      (item: SocialLinkModel) => ({
        link: item.socialUrl,
        url: require(`@/assets/icons/social/${icons[item.socialName]}`),
      })
    );
  }

  get description(): string | undefined {
    return this.companyData?.company?.description;
  }

  get media() {
    return this.companyData?.company?.media.map((item: MediaModel) => ({
      title: item.title,
      src: item.fileUrl,
      previewSrc: item.previewUrl,
      type: item.fileType,
    }));
  }

  isAuthenticated(isCandidate = true) {
    return isCandidate
      ? this.companyData?.isCandidate && this.companyData.isAuthenticated
      : this.companyData?.isAuthenticated;
  }

  async getCompanyDataRequest() {
    try {
      const candidateUrl = this.$router.currentRoute.fullPath;
      this.companyData = await PageService.getProfile<CompanyPageModel>(
        candidateUrl
      );
    } catch (e) {
      console.error(e.message);
    }
  }

  async getCompanyData() {
    try {
      this.loading = true;
      await this.getCompanyDataRequest();

      this.updateHeaderFooterInfo({
        contactAddress: [
          {
            type: ECandidatePageMenuAction.Contact,
            value: this.companyData?.company?.phoneNumber,
          },
          {
            type: ECandidatePageMenuAction.Address,
            value: this.companyData?.company?.address,
          },
          {
            type: ECandidatePageMenuAction.Button,
            value: this.companyData?.company?.website,
          },
        ],
        logoImageUrl: this.companyData?.company?.avatar?.fileUrl,
      });
    } catch (e) {
      console.error(e.message);
    } finally {
      this.loading = false;
    }
  }

  createActionDTO(isChecked: boolean): ActionTypes {
    return {
      companyId: this.companyData?.companyId,
      isChecked,
    };
  }

  async callToActionFavorites(isChecked: boolean) {
    try {
      await CompanyService.callToActionFavorites(
        this.createActionDTO(isChecked)
      );
    } catch (e) {
      console.error(e.message);
    }
  }

  async callToActionHideSearch(isChecked: boolean) {
    try {
      await CompanyService.callToActionHide(this.createActionDTO(isChecked));
    } catch (e) {
      console.error(e.message);
    }
  }

  async callToActionReport() {
    try {
      this.loadingReport = true;
      const companyId = this.companyData?.companyId;
      if (!companyId) {
        return;
      }

      await CompanyService.report(companyId);
      this.showReportModal = false;

      await this.getCompanyDataRequest();
    } catch (e) {
      console.error(e.message);
    } finally {
      this.loadingReport = false;
    }
  }

  openNotesPage() {
    const url = `${process.env.VUE_APP_MAIN_SITE_URL}/Iam/CandidateDashboard/CompanyNotes?companyId=${this.companyData?.companyId}&isInsideScoop=false&jobId=00000000-0000-0000-0000-000000000000&link=${window.location.href}`;
    window.open(url, "_blank");
  }

  async selectAction(value: MenuItemModel) {
    switch (value.action) {
      case ECompanyPageMenuAction.Favorite:
        await this.callToActionFavorites(Boolean(value.isChecked));
        await this.getCompanyDataRequest();
        break;
      case ECompanyPageMenuAction.Hide:
        await this.callToActionHideSearch(Boolean(value.isChecked));
        await this.getCompanyDataRequest();
        break;
      case ECompanyPageMenuAction.Notes:
        this.openNotesPage();
        break;
      case ECompanyPageMenuAction.Report:
        this.showReportModal = !this.showReportModal;
        break;

      default:
        break;
    }
  }
}
