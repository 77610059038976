












import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { MenuItemModel } from "@/models/common";
import CardMenu from "@/components/common/CardMenu.vue";
import ECompanyPageMenuAction from "@/models/enums/ECompanyPageMenuAction";

@Component({
  name: "CompanyMenu",
  components: {
    CardMenu,
  },
})
export default class extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  public readonly addedToFavorites!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  public readonly showedInSearch!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  public readonly disabledReport!: boolean;

  private showMenu = false;

  get menuItems() {
    return [
      {
        title: this.activateItem(
          this.addedToFavorites,
          "Remove from Favorites",
          "Add to Favorites"
        ),
        icon: this.activateItem(
          this.addedToFavorites,
          "mdi-star",
          "mdi-star-outline"
        ),
        iconColor: "#929292",
        action: ECompanyPageMenuAction.Favorite,
      },
      {
        title: this.activateItem(
          this.showedInSearch,
          "Show in Search",
          "Hide in Search"
        ),
        icon: this.activateItem(this.showedInSearch, "mdi-eye-off", "mdi-eye"),
        iconColor: "#929292",
        action: ECompanyPageMenuAction.Hide,
      },
      {
        title: "Notes",
        icon: "mdi-note",
        iconColor: "#929292",
        action: ECompanyPageMenuAction.Notes,
      },
      // {
      //   title: "Exclude",
      //   icon: "mdi-account-remove",
      //   iconColor: "#929292",
      //   action: ECompanyPageMenuAction.Exclude,
      // },
      {
        title: "Report",
        icon: "mdi-flag-variant",
        iconColor: "#BB4748",
        textColor: "#BB4748",
        separated: true,
        action: ECompanyPageMenuAction.Report,
        disabled: this.disabledReport,
      },
    ];
  }

  @Emit()
  selected(value: MenuItemModel) {
    switch (value.action) {
      case ECompanyPageMenuAction.Notes:
      case ECompanyPageMenuAction.Report:
        this.showMenu = false;
        break;
      default:
        break;
    }

    return value;
  }

  @Emit()
  input(value: boolean) {
    return value;
  }

  activateItem(value: boolean, activeValue: string, defaultValue: string) {
    return value ? activeValue : defaultValue;
  }
}
